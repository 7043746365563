import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import Layout from './components/Layout';
import Reservation from './Reservation'; // Import the Reservation component
import './CalendarCustom.css'; // Create a custom CSS file

const pb = new PocketBase('https://dekar.sk');

const CarDetail = () => {
  const { id: carId } = useParams();
  const [car, setCar] = useState(null);
  const [reservations, setReservations] = useState([]);
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showReservationForm, setShowReservationForm] = useState(false); // State to control Reservation component

  useEffect(() => {
    if (!carId) return;

    const fetchCarDetails = async () => {
      try {
        const carData = await pb.collection('cars').getOne(carId);
        setCar(carData);
      } catch (error) {
        console.error('Error fetching car details:', error);
      }
    };

    const fetchReservations = async () => {
      try {
        const reservationData = await pb.collection('reservations').getFullList({
          filter: `car_id = "${carId}"`,
        });
        setReservations(reservationData);
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

    fetchCarDetails();
    fetchReservations();
  }, [carId]);

  const isDateRangeOverlap = (start1, end1, start2, end2) => {
    return start1 <= end2 && end1 >= start2;
  };

  // Disable only the dates of reservations with status "paid"
  const disableReservedDates = ({ date, view }) => {
    if (view === 'month') {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today to compare only the date
      date.setHours(0, 0, 0, 0);  // Normalize the selected date
  
      if (date < today) {
        return true; // Disable past dates
      }
  
      return reservations.some((reservation) => {
        const fromDate = new Date(reservation.from);
        const toDate = new Date(reservation.to);
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(23, 59, 59, 999); // Include the entire "to" day
  
        // Disable dates for reservations with status "paid"
        if (reservation.paid === true) {
          return date >= fromDate && date <= toDate;
        }
  
        // Disable dates for unpaid reservations created less than 60 minutes ago
        const createdAt = new Date(reservation.created); // Assuming `created` is the creation timestamp
        const now = new Date();
        const timeDifference = (now - createdAt) / (1000 * 60); // Time difference in minutes
  
        if (reservation.paid === false && timeDifference < 60) {
          return date >= fromDate && date <= toDate;
        }
  
        return false;
      });
    }
  };
  

  const confirmReservation = () => {
    if (!selectedRange || selectedRange.length !== 2) return; // Ensure range is valid

    const selectedStart = new Date(selectedRange[0]);
    const selectedEnd = new Date(selectedRange[1]);

    const isOverlap = reservations.some((reservation) => {
      const fromDate = new Date(reservation.from);
      const toDate = new Date(reservation.to);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999); // Include the entire "to" day
      return isDateRangeOverlap(selectedStart, selectedEnd, fromDate, toDate);
    });

    if (isOverlap) {
      setSelectedRange([]);
    } else {
      setShowReservationForm(true); // Show the Reservation component if dates are valid
    }
  };

  return (
    <Layout>
      <div className="container mx-auto py-12 px-6">
        {showReservationForm ? (
          <Reservation carId={carId} selectedRange={selectedRange} />  // Show Reservation component
        ) : car ? (
          <div className="car-detail bg-gray-900 p-10 rounded-lg shadow-lg text-white grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
            {/* Left: Image Gallery */}
            <div className="relative h-full">
              <img
                src={`https://dekar.sk/api/files/${car.collectionId}/${car.id}/${car.images[0]}`}
                alt={car.name}
                className="rounded-lg shadow-lg w-full object-cover max-h-[400px] lg:max-h-[500px]" 
                style={{ objectFit: 'contain' }}  
              />
            </div>

            {/* Right: Car Details */}
            <div className="flex flex-col justify-center space-y-6">
              <h2 className="text-5xl font-extrabold text-gold">{car.name}</h2>
              <p className="text-xl text-gray-300">
                Cena: <span className="font-bold text-gold text-3xl">{car.price}€ / deň</span>
              </p>

              <button
                className="bg-gold text-black px-8 py-3 font-semibold rounded-full hover:bg-opacity-90 transition-all duration-300 shadow-lg focus:outline-none focus:ring-4 focus:ring-gold focus:ring-opacity-50"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                {showCalendar ? 'Skryť Kalendár' : 'Rezervovať'}
              </button>

              {showCalendar && (
                <div className="calendar-container bg-gray-800 p-8 rounded-lg shadow-inner mt-6">
                  <Calendar
                    onChange={setSelectedRange}
                    value={selectedRange}
                    selectRange={true}
                    tileDisabled={disableReservedDates}
                    className="custom-calendar" // Add this class for custom styling
                    />
                  <button
                    className="mt-4 bg-gold text-black px-8 py-3 font-semibold rounded-full hover:bg-opacity-90 transition-all duration-300 transform shadow-lg focus:outline-none focus:ring-4 focus:ring-gold focus:ring-opacity-50"
                    onClick={confirmReservation}
                  >
                    Potvrdiť Rezerváciu
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <p className="text-gray-400">Načítavam detaily vozidla...</p>
        )}
      </div>
    </Layout>
  );
};

export default CarDetail;
