import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import LoginForm from './admin/LoginForm';

const pb = new PocketBase('https://dekar.sk');

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Check if admin is already logged in
  useEffect(() => {
    if (pb.authStore.isValid) {
      // Redirect to /admin if user is already authenticated
      navigate('/admin');
    }
  }, [navigate]);

  // Handle login
  const handleLogin = async () => {
    try {
      const authData = await pb.admins.authWithPassword(email, password);
      if (authData) {
        // Redirect to /admin upon successful login
        navigate('/admin');
      }
    } catch (error) {
      setErrorMessage('Prihlásenie zlyhalo. Skontrolujte email a heslo.');
    }
  };

  return (
    <LoginForm
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handleLogin={handleLogin}
      errorMessage={errorMessage}
    />
  );
};

export default AdminLogin;
