import React, { useState, useEffect } from 'react';
import Layout from './components/Layout';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';



const Home = () => {
  const [cars, setCars] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const pb = new PocketBase('https://dekar.sk'); // Replace with your PocketBase URL
        const records = await pb.collection('cars').getFullList({
          sort: '-created',
        });
        setCars(records); // Set the cars in state
      } catch (error) {
        console.error('Error fetching cars:', error);
      }
    };
    fetchCars();
  }, []);

  return (
    <Layout>
        <section class="relative bg-cover bg-center h-screen shadow-luxury" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero.jpg)` }}>
            <div class="absolute inset-0 bg-black bg-opacity-70  flex items-center justify-center">
                <div class="text-center">
                    <h1 class="text-6xl font-bold mb-4 text-gold">Prenajmite si svoje auto už dnes!</h1>
                    <p class="text-2xl mb-6 text-gray-300">Dekar.sk - Spoľahlivá autopôžičovňa pre každého</p>
                    <Link
                      to="cars"
                      smooth={true}
                      duration={500}
                      className="bg-gold text-black px-8 py-4 font-semibold rounded-full hover:bg-opacity-90 transition duration-300 hover-translate shadow-luxury cursor-pointer"
                    >
                      Prezrieť ponuku
                    </Link>                
            </div>
            </div>
        </section>

        <section id="about" className="py-20 bg-black">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold text-gold mb-6">O nás</h2>
          <p className="text-xl text-gray-400 max-w-4xl mx-auto leading-relaxed">
            Sme Dekar.sk, profesionálna autopôžičovňa, ktorá sa špecializuje na poskytovanie
            vysoko kvalitných služieb pre našich zákazníkov.
          </p>
        </div>
      </section>


      {/* Cars Section */}
      <section id="cars" className="py-20 bg-gray-900">
        <div className="container mx-auto">
          <h2 className="text-5xl font-bold text-center text-gold mb-12">Naša ponuka</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
            {cars.length > 0 ? (
              cars.map((car) => (
                <div
                  key={car.id}
                  className="bg-gray-800 p-8 rounded-lg shadow-luxury transition-transform hover-translate"
                >
                  <img
                    src={`https://dekar.sk/api/files/${car.collectionId}/${car.id}/${car.images[0]}`}
                    alt={car.name}
                    className="rounded-lg mb-6 shadow-luxury"
                  />
                  <h3 className="text-2xl font-semibold mb-4 text-gold">{car.name}</h3>
                  <p className="text-xl text-gray-300">
                    Cena: <span className="font-bold text-gold">{car.price}€ / deň</span>
                  </p>
                  <button
                    onClick={() => navigate(`/car/${car.id}`)}
                    className="mt-6 inline-block bg-gold text-black px-6 py-3 rounded-full hover:bg-opacity-90 transition duration-300 hover-translate"
                  >
                    Rezervovať
                  </button>
                </div>
              ))
            ) : (
              <p className="text-xl text-gray-400">Načítavam ponuku áut...</p>
            )}
          </div>
        </div>
      </section>

      {/* Additional sections like contact can be added here */}
    </Layout>
  );
};

export default Home;
