import React, { useState, useRef } from 'react';
import PocketBase from 'pocketbase';
import { formatDateSlovak } from '../utils';
import { useDropzone } from 'react-dropzone';

const pb = new PocketBase('https://dekar.sk');

const ReservationList = ({
  reservations,
  carDetails,
  clientDetails,
  openClientDetailsModal,
  onHandOverToCustomer,
  onMarkReturnedByCustomer,
}) => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPhotos, setModalPhotos] = useState([]);
  const [currentReservationId, setCurrentReservationId] = useState(null); // Store current reservation for refreshing
  const fileInputRefs = useRef({}); // Track individual file input refs for resetting
  const [uploadedFiles, setUploadedFiles] = useState([]); // Fix: Declare uploadedFiles and its setter

  // Handle file input changes and track files for each reservation
  const handleFileChange = (reservationId, files) => {
    setSelectedFiles({ ...selectedFiles, [reservationId]: files });
  };

  // Upload car photos for a specific reservation
  const handleUploadClick = async (reservationId) => {
    const files = selectedFiles[reservationId];
    if (!files || files.length === 0) {
      alert('Please select files to upload');
      return;
    }

    try {
      const formData = new FormData();
      Array.from(files).forEach(file => {
        formData.append('car_photos', file); // Appending car photos
      });

      // Update the reservation with the uploaded files
      await pb.collection('reservations').update(reservationId, formData);
      console.log('Car photos uploaded successfully!');
      
      // Reset file input after successful upload
      if (fileInputRefs.current[reservationId]) {
        fileInputRefs.current[reservationId].value = '';
      }
      setSelectedFiles({ ...selectedFiles, [reservationId]: [] });

      // Immediately refresh the car photos to show the newly uploaded ones
      await refreshCarPhotos(reservationId);

      // Clear the uploaded files from the dropzone
      setUploadedFiles([]); // Clear files after upload

    } catch (error) {
      console.error('Error uploading car photos:', error);
      alert('Failed to upload car photos');
    }
  };


  // Fetch and display photos in a modal for the selected reservation
  const handleViewPhotosClick = async (reservationId) => {
    setCurrentReservationId(reservationId); // Set current reservation ID
    await refreshCarPhotos(reservationId);
  };

  // Refresh car photos for the given reservation
  const refreshCarPhotos = async (reservationId) => {
    try {
      const reservation = await pb.collection('reservations').getOne(reservationId);
      if (reservation.car_photos && reservation.car_photos.length > 0) {
        const photoUrls = reservation.car_photos.map(photo => pb.getFileUrl(reservation, photo));
        setModalPhotos(photoUrls);
        setIsModalOpen(true);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching car photos:', error);
      alert('Failed to load car photos');
    }
  };

  const onDrop = (acceptedFiles, reservationId) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    handleFileChange(reservationId, acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, currentReservationId),
    accept: 'image/*',
  });

  // Close the modal and clear current reservation
  const closeModal = () => {
    setIsModalOpen(false);
    setModalPhotos([]);
    setCurrentReservationId(null);
  };


  // Render individual reservation rows
  const renderReservationRow = (reservation) => {
    const carDetail = carDetails[reservation.id];
    const clientDetail = clientDetails[reservation.id];
    const paymentMethod = reservation.payment_cash ? 'Hotovosť' : 'Karta';

    return (
      <tr key={reservation.id} className="hover:bg-gray-100">
        <td className="px-4 py-3 border-b">
          <button
            className="text-blue-600 hover:underline"
            onClick={() => openClientDetailsModal(reservation.client_id)}
          >
            {clientDetail?.full_name || 'Načítavam...'}
          </button>
        </td>

        <td className="px-4 py-3 border-b">
          {carDetail?.name || 'Načítavam...'}
        </td>

        <td className="px-4 py-3 border-b">
          {formatDateSlovak(reservation.from)}
        </td>
        <td className="px-4 py-3 border-b">
          {formatDateSlovak(reservation.to)}
        </td>

        <td className="px-4 py-3 border-b">
          {reservation.paid ? (
            <span className="inline-block px-3 py-1 font-semibold text-green-800 bg-green-200 rounded-full">
              Zaplatená
            </span>
          ) : (
            <span className="inline-block px-3 py-1 font-semibold text-red-800 bg-red-200 rounded-full">
              Nezaplatená
            </span>
          )}
        </td>

        <td className="px-4 py-3 border-b">
          {paymentMethod}
        </td>

        <td className="px-4 py-3 border-b">
          {reservation.isReturnedByCustomer ? (
            <span className="inline-block px-3 py-1 font-semibold text-blue-800 bg-blue-200 rounded-full">
              Vozidlo bolo vrátené zákazníkom
            </span>
          ) : reservation.isHandedToCustomer ? (
            <>
              <span className="inline-block px-3 py-1 font-semibold text-green-800 bg-green-200 rounded-full">
                Odovzdané zákazníkovi
              </span>
              <button
                className="ml-2 bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
                onClick={() => onMarkReturnedByCustomer(reservation.id)}
              >
                Vrátené zákazníkom
              </button>
            </>
          ) : (
            <button
              className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition duration-300"
              onClick={() => onHandOverToCustomer(reservation.id)}
            >
              Odovzdať zákazníkovi
            </button>
          )}
        </td>

        <td className="px-4 py-3 border-b">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
            onClick={() => handleViewPhotosClick(reservation.id)}
          >
            Pozrieť fotky vozidla
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold mb-6">Rezervácie</h2>
      {reservations.length > 0 ? (
        <div className="bg-white shadow-md rounded-lg overflow-x-auto">
          <table className="w-full table-auto text-left text-xs lg:text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]">Meno klienta</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]">Vozidlo</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]">Dátum od</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]">Dátum do</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]">Zaplatene</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[85px]">Platba</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[240px]">Akcie</th>
                <th className="px-4 py-3 border-b text-gray-700 whitespace-nowrap min-w-[100px]"></th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap text-xs lg:text-sm">
              {reservations.map((reservation) => renderReservationRow(reservation))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-400">Žiadne rezervácie k zobrazeniu.</p>
      )}

      {/* Modal for viewing photos */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-4 max-w-3xl w-full relative max-h-[80vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4 text-center">Fotky vozidla</h3>

            <div className="grid grid-cols-3 gap-4 mb-6">
              {modalPhotos.map((photoUrl, index) => (
                <div
                  key={index}
                  className="w-full h-48 flex justify-center items-center overflow-hidden border border-gray-300 rounded-lg"
                >
                  <img
                    src={photoUrl}
                    alt={`Car photo ${index + 1}`}
                    className="object-cover h-full w-full"
                  />
                </div>
              ))}
            </div>

            {/* File Upload Area */}
            <div
              {...getRootProps({
                className:
                  'border-2 border-dashed border-gray-300 p-4 text-center cursor-pointer rounded-lg mb-4',
              })}
            >
              <input {...getInputProps()} />
              <p className="text-gray-600">
              Presuňte sem súbory alebo kliknite pre výber súborov
              </p>
            </div>

            {/* Display selected files */}
            <div className="grid grid-cols-2 gap-2 mb-4">
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="p-2 border border-gray-300 rounded-md flex items-center justify-between"
                >
                  <span className="truncate">{file.name}</span>
                </div>
              ))}
            </div>

            <button
              className="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300 mt-2 w-full"
              onClick={() => handleUploadClick(currentReservationId)}
            >
              Nahrať fotky vozidla
            </button>


            {/* Close button */}
            <button
              className="absolute top-4 right-4 bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300"
              onClick={closeModal}
            >
              Zatvoriť
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default ReservationList;
