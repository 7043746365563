import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate, useLocation } from 'react-router-dom';


const pb = new PocketBase('https://dekar.sk');

const LayoutAdmin = ({ children }) => {
  const navigate = useNavigate(); // Tu je import navigácie
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);

  // Check if the user is logged in
  useEffect(() => {
    setLoggedIn(pb.authStore.isValid);
  }, []);

  const handleLogout = async () => {
    try {
      await pb.authStore.clear(); // Clear the auth store (logout)
      setLoggedIn(false);
      navigate('/admin/login')
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  // Determine if the current route is active
  const isActiveRoute = (route) => location.pathname === route;

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {loggedIn && (
        <nav className="bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex space-x-4">
                <button
                  onClick={() => navigate('/admin/')}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    isActiveRoute('/admin/')
                      ? 'text-gray-900 bg-gray-200'
                      : 'text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  Objednávky
                </button>
                <button
                  onClick={() => navigate('/admin/cars')}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    isActiveRoute('/admin/cars')
                      ? 'text-gray-900 bg-gray-200'
                      : 'text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  Vozidlá
                </button>
                <button
                  onClick={handleLogout}
                  className="text-gray-600 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200"
                >
                  Odhlásenie
                </button>
              </div>
            </div>
          </div>
        </nav>
      )}

      <div className="flex-grow max-w-7xl mx-auto p-6">{children}</div>
    </div>
  );
};

export default LayoutAdmin;
