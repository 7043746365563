import React, { useState, useEffect, useCallback } from 'react';
import PocketBase from 'pocketbase';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom'; // Importovanie useNavigate pre presmerovanie
import LayoutAdmin from './components/admin/Layout';

const pb = new PocketBase('https://dekar.sk');

const AdminCars = () => {
  const [cars, setCars] = useState([]); // State for storing cars
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [newCarData, setNewCarData] = useState({ name: '', price: '', vin_number: '', note: '' }); // State for new car form
  const [selectedFiles, setSelectedFiles] = useState([]); // State for uploaded files
  const [filePreviews, setFilePreviews] = useState([]); // State for file previews
  const [errorMessage, setErrorMessage] = useState(''); // State for validation errors
  const navigate = useNavigate(); // Hook pre presmerovanie

  useEffect(() => {
    fetchCars();
  }, []);

  // Function to fetch cars
  const fetchCars = async () => {
    try {
      const records = await pb.collection('cars').getFullList({ sort: '-created' });
      setCars(records);
    } catch (error) {
      console.error('Error fetching cars:', error);
    }
  };

  const handleDeleteCar = async (carId) => {
    if (window.confirm('Naozaj chcete odstrániť toto auto?')) {
      try {
        await pb.collection('cars').delete(carId); // Delete the car
        fetchCars(); // Refresh car list
        alert('Auto bolo úspešne odstránené!');
      } catch (error) {
        console.error('Error deleting car:', error);
      }
    }
  };

  // Function to handle the car creation form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCarData({ ...newCarData, [name]: value });
  };

  const handleCreateCar = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('name', newCarData.name);
    formData.append('price', newCarData.price);
    formData.append('vin_number', newCarData.vin_number); // Optional
    formData.append('note', newCarData.note); // Optional

    // Append selected files (images)
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });

    try {
      await pb.collection('cars').create(formData); // Create car with form data and images
      setIsModalOpen(false); // Close modal
      fetchCars(); // Refresh car list
      alert('Auto bolo úspešne pridané!');
      setNewCarData({ name: '', price: '', vin_number: '', note: '' }); // Reset form
      setSelectedFiles([]); // Clear selected files
      setErrorMessage(''); // Clear error message
    } catch (error) {
      console.error('Error creating car:', error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFiles([]); // Clear selected files
    setFilePreviews([]);  // Clear previews
  };
  

  // Reorder files when dragged
  const handleDragStart = (index) => {
    return (event) => {
      event.dataTransfer.setData('dragIndex', index);
    };
  };

  const handleDrop = (index) => {
    return (event) => {
      const dragIndex = event.dataTransfer.getData('dragIndex');
      if (dragIndex === undefined) return;

      const filesCopy = [...selectedFiles];
      const previewsCopy = [...filePreviews];

      const [draggedFile] = filesCopy.splice(dragIndex, 1);
      const [draggedPreview] = previewsCopy.splice(dragIndex, 1);

      filesCopy.splice(index, 0, draggedFile);
      previewsCopy.splice(index, 0, draggedPreview);

      setSelectedFiles(filesCopy);
      setFilePreviews(previewsCopy);
    };
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Validation check before submitting the form
  const validateForm = () => {
    if (!newCarData.name || !newCarData.price) {
      setErrorMessage('Názov auta a cena sú povinné.');
      return false;
    }
    return true;
  };

  // Handle file drop (react-dropzone)
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    // Create file previews
    const previews = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Clean up object URLs after the component unmounts
  useEffect(() => {
    return () => {
      filePreviews.forEach((preview) => URL.revokeObjectURL(preview.preview));
    };
  }, [filePreviews]);

  // Function to remove a file from selectedFiles
  const handleRemoveFile = (fileName) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setFilePreviews((prevPreviews) => prevPreviews.filter((preview) => preview.file.name !== fileName));
  };

  // Check if admin is logged in using PocketBase's authStore
  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate('/admin/login'); // Ak nie je prihlásený, presmerujeme na /admin/login
    } else {
      fetchCars(); // Ak je prihlásený, načítame dáta
    }
  }, [navigate]);

  return (
    <LayoutAdmin>
      <div className="w-full">
        <h2 className="text-3xl font-bold mb-6">Spravovanie áut</h2>

        <button
          className="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300 mb-4"
          onClick={() => setIsModalOpen(true)}
        >
          Pridať auto
        </button>

        <div className="bg-white shadow-md rounded-lg overflow-x-auto">
          <table className="w-full table-auto text-left text-xs lg:text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 border-b text-gray-700 min-w-[200px]">Názov auta</th>
                <th className="px-4 py-3 border-b text-gray-700 min-w-[100px]">Cena na deň</th>
                <th className="px-4 py-3 border-b text-gray-700 min-w-[150px]">VIN číslo</th>
                <th className="px-4 py-3 border-b text-gray-700 min-w-[200px]">Poznámka</th>
                <th className="px-4 py-3 border-b text-gray-700 min-w-[100px]">Akcie</th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap text-xs lg:text-sm">
              {cars.map((car) => (
                <tr key={car.id} className="hover:bg-gray-100">
                  <td className="px-4 py-3 border-b">{car.name}</td>
                  <td className="px-4 py-3 border-b">{car.price} €</td>
                  <td className="px-4 py-3 border-b">{car.vin_number || '-'}</td>
                  <td className="px-4 py-3 border-b">{car.note || '-'}</td>
                  <td className="px-4 py-3 border-b">
                    <button
                      className="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300"
                      onClick={() => handleDeleteCar(car.id)}
                    >
                      Odstrániť
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for creating a car */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-lg w-full">
              <h3 className="text-xl font-bold mb-4">Pridať nové auto</h3>

              {errorMessage && <p className="text-red-600 mb-4">{errorMessage}</p>}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Názov auta</label>
                <input
                  type="text"
                  name="name"
                  value={newCarData.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Cena za deň</label>
                <input
                  type="number"
                  name="price"
                  value={newCarData.price}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">VIN číslo (voliteľné)</label>
                <input
                  type="text"
                  name="vin_number"
                  value={newCarData.vin_number}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Poznámka (voliteľné)</label>
                <input
                  type="text"
                  name="note"
                  value={newCarData.note}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>              
              <div {...getRootProps()} className="mb-4 border-2 border-dashed border-gray-300 p-4 rounded-md">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Pustite súbory sem ...</p>
                ) : (
                  <p>Presuňte sem súbory alebo kliknite pre výber súborov</p>
                )}
              </div>

              {/* Display image previews */}
              <div className="flex flex-wrap gap-4 mb-4">
                {filePreviews.map(({ preview, file }, index) => (
                  <div
                    key={file.name}
                    className="relative"
                    draggable
                    onDragStart={handleDragStart(index)}
                    onDrop={handleDrop(index)}
                    onDragOver={handleDragOver}
                  >
                    <img src={preview} alt={file.name} className="w-24 h-24 object-cover rounded-md" />
                    <button
                      onClick={() => handleRemoveFile(file.name)}
                      className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex justify-end">
              <button
                  className="bg-gray-600 text-white px-4 py-2 rounded-full hover:bg-gray-700 transition duration-300 mr-2"
                  onClick={handleCloseModal} // Use handleCloseModal instead of setting setIsModalOpen directly
                >
                  Zrušiť
                </button>
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
                  onClick={handleCreateCar}
                >
                  Pridať
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </LayoutAdmin>
  );
};

export default AdminCars;
