import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import lightbox styles

const ClientDetailsModal = ({ isModalOpen, setIsModalOpen, selectedClient }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');

  if (!isModalOpen || !selectedClient) {
    return null;
  }

  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
    setLightboxOpen(true);
  };

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ease-out">
      <div className="bg-white p-6 rounded-2xl shadow-2xl max-w-xl w-full relative max-h-[90vh] overflow-y-auto transition-transform duration-300 transform scale-100 ease-out">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-300 transition-colors duration-200"
          onClick={() => setIsModalOpen(false)}
        >
          <img
            src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/icons/x-lg.svg"
            alt="close"
            className="w-4 h-4"
          />
        </button>

        {/* Modal Header */}
        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Údaje o zákazníkovi</h3>
        </div>

        {/* Client Details */}
        <div className="space-y-4 text-gray-700">
          <p><strong>Meno:</strong> {selectedClient.full_name}</p>
          <p><strong>Dátum narodenia:</strong> {new Date(selectedClient.birth).toLocaleDateString()} (deň/mesiac/rok)</p>

          <div>
            <p className="font-semibold">Občiansky preukaz (predná strana):</p>
            <img
              src={`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.id_document_1}`}
              alt="Občiansky preukaz predná strana"
              className="w-full h-48 object-cover rounded-lg mt-2 shadow-md cursor-pointer"
              onClick={() => openLightbox(`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.id_document_1}`)}
            />
          </div>

          <div>
            <p className="font-semibold">Občiansky preukaz (zadná strana):</p>
            <img
              src={`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.id_document_2}`}
              alt="Občiansky preukaz zadná strana"
              className="w-full h-48 object-cover rounded-lg mt-2 shadow-md cursor-pointer"
              onClick={() => openLightbox(`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.id_document_2}`)}
            />
          </div>

          <div>
            <p className="font-semibold">Vodičský preukaz:</p>
            <img
              src={`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.driver_license}`}
              alt="Vodičský preukaz"
              className="w-full h-48 object-cover rounded-lg mt-2 shadow-md cursor-pointer"
              onClick={() => openLightbox(`http://127.0.0.1:8090/api/files/clients/${selectedClient.id}/${selectedClient.driver_license}`)}
            />
          </div>
        </div>
      </div>

      {/* Lightbox for displaying large images */}
      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setLightboxOpen(false)}
        />
      )}
    </div>
  );
};

export default ClientDetailsModal;
