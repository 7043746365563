import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleNavigateWithHash = (hash) => {
    if (location.pathname !== '/') {
      navigate('/', { replace: true }); // Najprv navigujeme na domovskú stránku
      setTimeout(() => {
        window.location.hash = hash; // Nastavíme hash fragment po malej pauze
      }, 0); // Použijeme timeout 0 ms na to, aby sme počkali na navigáciu
    } else {
      // Ak sme už na domovskej stránke, použijeme smooth scrollovanie
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <header className="sticky top-0 bg-black z-50 shadow-md">
      <div className="container mx-auto flex justify-between items-center p-4">
        <button
          onClick={handleLogoClick}
          className="text-3xl font-bold text-gold cursor-pointer"
        >
          Dekar
        </button>
        <nav className="hidden md:flex space-x-8">
          <button
            onClick={() => handleNavigateWithHash('#about')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            O nás
          </button>
          <button
            onClick={() => handleNavigateWithHash('#cars')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            Naša ponuka
          </button>
        </nav>
        <button className="md:hidden text-white text-2xl" id="mobile-menu-button">
          <i className="bi bi-list"></i>
        </button>
      </div>
      <div className="hidden" id="mobile-menu">
        <nav className="flex flex-col items-center space-y-4 py-4">
          <button
            onClick={() => handleNavigateWithHash('#about')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            O nás
          </button>
          <button
            onClick={() => handleNavigateWithHash('#cars')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            Naša ponuka
          </button>
          <button
            onClick={() => handleNavigateWithHash('#pricing')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            Cenník
          </button>
          <button
            onClick={() => handleNavigateWithHash('#contact')}
            className="hover:text-gold transition duration-300 cursor-pointer"
          >
            Kontakt
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
