import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import CarDetail from './CarDetail';
import Admin from './Admin';
import AdminCars from './AdminCars';
import AdminLogin from './components/LoginAdmin';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Stav pre overenie hesla
  const [passwordInput, setPasswordInput] = useState(''); // Stav pre vstupné heslo

  const correctPassword = 'adam123'; // Nastav svoje heslo sem

  // Funkcia na overenie hesla
  const handleLogin = () => {
    if (passwordInput === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Uložíme stav do localStorage
    } else {
      alert('Nesprávne heslo');
    }
  };

  // Pri načítaní aplikácie skontrolujeme, či je heslo už uložené
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="w-full max-w-sm">
          <div className="bg-white shadow-lg rounded-lg px-10 py-8">
            <h2 className="text-center text-3xl font-semibold text-gray-800 mb-6">Vložte heslo</h2>
            <input
              type="password"
              placeholder="Zadajte heslo"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              className="block w-full bg-gray-100 border border-gray-300 rounded-lg py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            />
            <button
              onClick={handleLogin}
              className="w-full mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Prihlásiť sa
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/car/:id" element={<CarDetail />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/cars" element={<AdminCars />} />
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
