import React, { useState } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://dekar.sk');

const Reservation = ({ carId, selectedRange }) => {
  const [step, setStep] = useState(1); // Step 1: User Details, Step 2: Review, Step 3: Confirmation
  const [fullName, setFullName] = useState('');
  const [idDocument1, setIdDocument1] = useState(null);
  const [idDocument2, setIdDocument2] = useState(null);
  const [birthDate, setBirthDate] = useState('');
  const [driverLicense, setDriverLicense] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('cash'); // default to 'cash'
  const [errorMessage, setErrorMessage] = useState('');

  const handleNextStep = () => {
    if (!fullName || !idDocument1 || !idDocument2 || !birthDate || !driverLicense) {
      setErrorMessage('Všetky polia sú povinné.');
      return;
    }
    setErrorMessage('');
    setStep(2); // Proceed to review step if all user details are filled
  };

  const handlePrevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleFileUpload = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleSubmitReservation = async () => {
    try {
      // Step 1: Create client
      const clientData = {
        full_name: fullName,
        birth: new Date(birthDate).toISOString(),
        id_document_1: idDocument1,
        id_document_2: idDocument2,
        driver_license: driverLicense,
      };
      const clientRecord = await pb.collection('clients').create(clientData);

      // Step 2: Create reservation based on payment method
      const reservationData = {
        from: selectedRange[0].toISOString(),
        to: selectedRange[1].toISOString(),
        car_id: carId,
        client_id: clientRecord.id, // Link client to reservation
        paid: paymentMethod === 'cash', // Paid if cash
        isHandedToCustomer: false, // Only true for cash
        isReturnedByCustomer: false, // Only true for cash
        payment_cash: paymentMethod === 'cash', // true if cash, false otherwise
      };
      await pb.collection('reservations').create(reservationData);

      setStep(3); // Proceed to confirmation
    } catch (error) {
      console.error('Error creating reservation:', error);
      setErrorMessage('Nastala chyba pri vytváraní rezervácie. Skúste to prosím neskôr.');
    }
  };

  return (
    <div className="container mx-auto py-12 px-6">
      {step === 1 && (
        <div className="bg-gray-900 p-10 rounded-lg shadow-lg text-white">
          <h2 className="text-4xl font-bold mb-6">Zadajte svoje údaje</h2>
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
          <label className="block mb-4">
            <span className="text-lg">Meno a priezvisko</span>
            <input
              type="text"
              className="w-full p-2 rounded mt-2 bg-gray-800 text-white"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-lg">Dátum narodenia</span>
            <input
              type="date"
              className="w-full p-2 rounded mt-2 bg-gray-800 text-white"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-lg">Občiansky preukaz (predná strana)</span>
            <input
              type="file"
              className="w-full p-2 rounded mt-2 bg-gray-800 text-white"
              onChange={(e) => handleFileUpload(e, setIdDocument1)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-lg">Občiansky preukaz (zadná strana)</span>
            <input
              type="file"
              className="w-full p-2 rounded mt-2 bg-gray-800 text-white"
              onChange={(e) => handleFileUpload(e, setIdDocument2)}
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-lg">Vodičský preukaz</span>
            <input
              type="file"
              className="w-full p-2 rounded mt-2 bg-gray-800 text-white"
              onChange={(e) => handleFileUpload(e, setDriverLicense)}
              required
            />
          </label>
          <div className="mb-4">
            <h3 className="text-lg">Spôsob platby</h3>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="cash"
                  checked={paymentMethod === 'cash'}
                  onChange={() => setPaymentMethod('cash')}
                />
                <span className="ml-2">Hotovosť</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="card"
                  checked={paymentMethod === 'card'}
                  onChange={() => setPaymentMethod('card')}
                />
                <span className="ml-2">Platba kartou</span>
              </label>
            </div>
          </div>
          <button
            className="bg-gold text-black px-6 py-3 font-semibold rounded-full mt-4 hover:bg-opacity-90"
            onClick={handleNextStep}
          >
            Ďalej
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="bg-gray-900 p-10 rounded-lg shadow-lg text-white">
          <h2 className="text-4xl font-bold mb-6">Rekapitulácia</h2>
          <p className="text-lg mb-4">Meno a priezvisko: {fullName}</p>
          <p className="text-lg mb-4">Dátum narodenia: {new Date(birthDate).toLocaleDateString()}</p>
          <p className="text-lg mb-4">
            Obdobie rezervácie: {selectedRange[0].toLocaleDateString()} - {selectedRange[1].toLocaleDateString()}
          </p>
          <p className="text-lg mb-4">Spôsob platby: {paymentMethod === 'cash' ? 'Hotovosť' : 'Platba kartou'}</p>
          <div className="flex space-x-4">
            <button
              className="bg-gray-700 text-white px-6 py-3 font-semibold rounded-full hover:bg-opacity-90"
              onClick={handlePrevStep}
            >
              Späť
            </button>
            <button
              className="bg-gold text-black px-6 py-3 font-semibold rounded-full hover:bg-opacity-90"
              onClick={handleSubmitReservation}
            >
              Potvrdiť
            </button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="bg-gray-900 p-10 rounded-lg shadow-lg text-white">
          <h2 className="text-4xl font-bold mb-6">Rezervácia potvrdená!</h2>
          <p className="text-lg">Vaša rezervácia bola úspešne vytvorená.</p>
        </div>
      )}
    </div>
  );
};

export default Reservation;
