import React from 'react';

const Footer = () => {
  return (
    <footer className="py-8 bg-black">
      <div className="container mx-auto text-center">
        <p className="text-gray-500">&copy; 2024 Dekar.sk | Všetky práva vyhradené</p>
        <div className="mt-6 space-x-6">
          <a href="#" className="text-gray-500 hover:text-gold transition duration-300">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-gold transition duration-300">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="#" className="text-gray-500 hover:text-gold transition duration-300">
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
