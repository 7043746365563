// Utility function to manually format date in Slovak
export const formatDateSlovak = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'Neplatný dátum'; // Return an error if the date is invalid
    }
  
    const months = [
      'január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'
    ];
  
    const day = date.getUTCDate(); // Get the day from the date object
    const month = months[date.getUTCMonth()]; // Get the month (using our Slovak months array)
    const year = date.getUTCFullYear(); // Get the year
  
    return `${day}. ${month} ${year}`; // Return the formatted date
};
  