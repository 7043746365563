import React from 'react';

const LoginForm = ({ email, setEmail, password, setPassword, handleLogin, errorMessage }) => {
  return (
    <div className="bg-white p-10 rounded-xl shadow-2xl max-w-md mx-auto">
      <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-800">Admin Prihlásenie</h2>
      {errorMessage && <p className="text-red-600 font-medium mb-6 text-center">{errorMessage}</p>}
      <label className="block mb-6">
        <span className="text-lg font-medium text-gray-700">Email</span>
        <input
          type="email"
          className="w-full mt-2 p-4 rounded-lg bg-gray-100 border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-200"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <label className="block mb-6">
        <span className="text-lg font-medium text-gray-700">Heslo</span>
        <input
          type="password"
          className="w-full mt-2 p-4 rounded-lg bg-gray-100 border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-200"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      <button
        className="w-full bg-blue-600 text-white py-3 font-bold rounded-full mt-6 hover:bg-blue-700 transition-all duration-300 ease-in-out shadow-lg transform hover:scale-105"
        onClick={handleLogin}
      >
        Prihlásiť sa
      </button>
    </div>
  );
};

export default LoginForm;
