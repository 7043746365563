import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom'; // Importovanie useNavigate pre presmerovanie
import LayoutAdmin from './components/admin/Layout';
import LoginForm from './components/admin/LoginForm';
import ReservationList from './components/admin/ReservationList';
import ClientDetailsModal from './components/admin/ClientDetailsModal';

const pb = new PocketBase('https://dekar.sk');

const Admin = () => {
  const [reservations, setReservations] = useState([]);
  const [carDetails, setCarDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [email, setEmail] = useState(''); // State for login email
  const [password, setPassword] = useState(''); // State for login password
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const navigate = useNavigate(); // Hook pre presmerovanie
  const [selectedCarPhotos, setSelectedCarPhotos] = useState([]);

  // Function to handle login
  const handleLogin = async (email, password) => {
    try {
      await pb.admins.authWithPassword(email, password);
      fetchReservations();
    } catch (error) {
      setErrorMessage('Prihlásenie zlyhalo, skontrolujte svoje údaje.');
    }
  };

  // Fetch car details by car ID
  const fetchCarDetails = async (carId) => {
    const carData = await pb.collection('cars').getOne(carId);
    return carData;
  };

  // Fetch client details by client ID
  const fetchClientDetails = async (clientId) => {
    const clientData = await pb.collection('clients').getOne(clientId);
    return clientData;
  };

    // Function to mark a car as handed to the customer
    const onHandOverToCustomer = async (reservationId) => {
      try {
        const updatedReservation = await pb.collection('reservations').update(reservationId, {
          isHandedToCustomer: true,
        });

        setReservations((prevReservations) =>
          prevReservations.map((reservation) =>
            reservation.id === reservationId
              ? { ...reservation, isHandedToCustomer: true }
              : reservation
          )
        );
      } catch (error) {
        console.error('Error handing over to customer:', error);
      }
    };

  // Function to mark a car as returned by the customer
  const onMarkReturnedByCustomer = async (reservationId) => {
    try {
      const updatedReservation = await pb.collection('reservations').update(reservationId, {
        isReturnedByCustomer: true,
      });

      setReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation.id === reservationId
            ? { ...reservation, isReturnedByCustomer: true }
            : reservation
        )
      );
    } catch (error) {
      console.error('Error marking returned by customer:', error);
    }
  };

  // Fetch reservations and associated car and client details
  const fetchReservations = async () => {
    try {
      const reservationData = await pb.collection('reservations').getFullList({
        sort: '-created',
        autoCancel: false,
      });

      const fetchedCarDetails = {};
      const fetchedClientDetails = {};

      for (const reservation of reservationData) {
        const carData = await fetchCarDetails(reservation.car_id);
        fetchedCarDetails[reservation.id] = carData;

        const clientData = await fetchClientDetails(reservation.client_id);
        fetchedClientDetails[reservation.id] = clientData;
      }

      setCarDetails(fetchedCarDetails);
      setClientDetails(fetchedClientDetails);
      setReservations(reservationData);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  // Function to upload car photos
  const uploadCarPhotos = async (carId, files) => {
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('car_photos', file));

      await pb.collection('cars').update(carId, formData);
      alert('Fotky boli úspešne nahraté');
    } catch (error) {
      console.error('Error uploading car photos:', error);
    }
  };

  // Function to fetch car photos
  const fetchCarPhotos = async (carId) => {
    try {
      const carData = await fetchCarDetails(carId);
      setSelectedCarPhotos(carData.car_photos || []);
    } catch (error) {
      console.error('Error fetching car photos:', error);
    }
  };

  // Check if admin is logged in using PocketBase's authStore
  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate('/admin/login'); // Ak nie je prihlásený, presmerujeme na /admin/login
    } else {
      fetchReservations(); // Ak je prihlásený, načítame dáta
    }
  }, [navigate]);

  // Open client details modal
  const openClientDetailsModal = async (clientId) => {
    const client = await fetchClientDetails(clientId);
    setSelectedClient(client);
    setIsModalOpen(true);
  };

  return (
    <LayoutAdmin>
      <div className="container mx-auto py-12 px-6 text-gray-800">
        {!pb.authStore.isValid ? (
           <LoginForm
           email={email}
           setEmail={setEmail}
           password={password}
           setPassword={setPassword}
           handleLogin={handleLogin}
           errorMessage={errorMessage}
         />
        ) : (
          <div>
            <ReservationList
              reservations={reservations}
              carDetails={carDetails}
              clientDetails={clientDetails}
              openClientDetailsModal={openClientDetailsModal}
              uploadCarPhotos={uploadCarPhotos}
              fetchCarPhotos={fetchCarPhotos}
              selectedCarPhotos={selectedCarPhotos}
              onHandOverToCustomer={onHandOverToCustomer}
              onMarkReturnedByCustomer={onMarkReturnedByCustomer}
            />
          </div>
        )}
        <ClientDetailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedClient={selectedClient}
        />
      </div>
    </LayoutAdmin>
  );
};

export default Admin;
